export default {
  type: null,
  variant: null,
  tier: null,
  effect:{
    duration: null,
    value: null,
    max_value: null,
    min_value: null,
    is_randomized: true
  },
  require_level: 0
}