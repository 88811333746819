<template>
  <div>
    <b-button variant="primary" @click="isModalShow=true">
    <b-spinner v-if="isLoading" small class="mr-50" />
    <feather-icon v-else icon="PlusIcon" class="mr-50" />
    <span class="align-middle">Add New</span>
    </b-button>
    <b-modal
      v-model="isModalShow"
      title="Create new item"
      hide-footer
      size="lg" modal-class="modal-primary"
    >
      <validation-observer
        ref="createForm"
        v-slot="{ handleSubmit }"
      >
        <b-form @submit.stop.prevent="handleSubmit(onSubmit)">
          <validation-provider
            name="Type"
            v-slot="validationContext"
            :rules="{ required: true }"
          >
            <b-form-group
              label="Item Type"
              label-for="h-item-type"
            >
              <b-form-select 
                v-model="itemData.type"
                :options="types_options"
                :state="getValidationState(validationContext)"
                aria-describedby="input-type-feedback"
              />
              <b-form-invalid-feedback id="input-type-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <validation-provider
            name="Variant"
            v-slot="validationContext"
            :rules="{ required: true }"
          >
            <b-form-group
              label="Item Variant"
              label-for="h-item-variant"
            >
              <b-form-select 
                v-model="itemData.variant" 
                :options="itemVariant" 
                :state="getValidationState(validationContext)"
                aria-describedby="input-variant-feedback"
              />
              <b-form-invalid-feedback id="input-variant-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <validation-provider
            name="Tier"
            v-slot="validationContext"
            :rules="{ required: true }"
          >
            <b-form-group
              label="Item Tier"
              label-for="h-item-tier"
            >
              <b-form-select 
                v-model="itemData.tier" 
                :options="itemLevel" 
                :state="getValidationState(validationContext)"
                aria-describedby="input-tier-feedback"
              />
              <b-form-invalid-feedback id="input-tier-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <validation-provider
            name="duration"
            v-slot="validationContext"
            :rules="{ required: true }"
          >
            <b-form-group
              v-if="itemData.type == 'SMOOTHY'"
              label="Duration (minutes)"
              label-for="h-duration"
            >
              <b-form-input 
                v-model="itemData.effect.duration" 
                :state="getValidationState(validationContext)"
                aria-describedby="input-duration-feedback"
                type="number"
              />
              <b-form-invalid-feedback id="input-duration-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <b-form-group
            v-if="itemData.type == 'EQUIPMENT'"
            label="Require level"
            label-for="h-require-level"
          >
            <b-form-input v-model="itemData.require_level" type="number" />
          </b-form-group>
          <b-form-group
            label="Random effect"
            label-for="h-randomized"
          >
            <b-form-checkbox v-model="itemData.effect.is_randomized" />
          </b-form-group>
          <validation-provider
            v-if="!itemData.effect.is_randomized"
            name="effect value"
            v-slot="validationContext"
            :rules="{ required: true }"
          >
            <b-form-group
              label="Effect value"
              label-for="h-value"
            >
              <b-form-input 
                v-model="itemData.effect.value" 
                :state="getValidationState(validationContext)"
                aria-describedby="input-value-feedback"
                type="number"
              />
              <b-form-invalid-feedback id="input-value-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <template v-else>
            <validation-provider
              name="min effect value"
              v-slot="validationContext"
              :rules="{ required: true }"
            >
              <b-form-group
                label="Min effect value"
                label-for="h-min-value"
              >
                <b-form-input 
                  v-model="itemData.effect.min_value" 
                  :state="getValidationState(validationContext)"
                  aria-describedby="input-minvalue-feedback"
                  type="number"
                />
                <b-form-invalid-feedback id="input-minvalue-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
            <validation-provider
              name="max effect value"
              v-slot="validationContext"
              :rules="{ required: true }"
            >
              <b-form-group
                label="Max effect value"
                label-for="h-max-value"
              >
                <b-form-input 
                  v-model="itemData.effect.max_value" 
                  :state="getValidationState(validationContext)"
                  aria-describedby="input-maxvalue-feedback"
                  type="number"
                />
                <b-form-invalid-feedback id="input-maxvalue-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </template>
        </b-form>
        
        <div class="d-flex justify-content-between my-2">
          <b-button variant="danger" @click="cancel">Cancel</b-button>
          <b-button type="submit" variant="primary" @click="create">Create</b-button>
        </div>
      </validation-observer>
      
      <hr>
      
    </b-modal>
  </div>
</template>

<script>
import service from "../../service";
import config from "../../../items/config";
import { ValidationProvider, ValidationObserver } from 'vee-validate/dist/vee-validate.full'
import defaultObject from "../../defaultObject"
export default {
  components:{
    ValidationProvider,
    ValidationObserver
  },
  data(){
    return{
      isLoading: false,
      isModalShow: false,
      name: null,
      itemData: JSON.parse(JSON.stringify(defaultObject)),
      types_options:[
        {text: "Smoothies", value: "SMOOTHY"},
        {text: "Clothes", value: "EQUIPMENT"},
      ],
    }
  },
  computed:{
    itemVariant(){
      if (this.itemData.type == "SMOOTHY"){
        return config.SMOOTHY
      }
      if (this.itemData.type == "EQUIPMENT"){
        return config.EQUIPMENT
      }
      if (this.itemData.type == "BOX"){
        return null
      }
      return null
    },
    itemLevel(){
      if (this.itemVariant && this.itemData.variant){
        let variant = this.itemVariant.find((x)=>{
          return x.value == this.itemData.variant
        })
        return variant ? variant.tier : null
      }
      return null
    }
  },
  methods: {
    async create() {
      let validation = await this.$refs.createForm.validate();
      if (validation){
        this.isLoading = true;
        let new_item = await service.create(JSON.stringify(this.itemData));
        this.isLoading = false;
        if (new_item) {
          this.$store.dispatch('pushSuccessNotify', { text: 'Item created!' })
          this.$emit("create");
          this.cancel();
        }
      }
    },
    cancel(){
      this.isModalShow = false;
      this.itemData = JSON.parse(JSON.stringify(defaultObject))
    },
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
  }
}
</script>