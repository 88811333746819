<template>
  <div class="page-content">
    <b-row>
      <b-col md="10">
        <page-breadcrumb title="Items Class" class="mb-3" />
      </b-col>
    </b-row>
    <b-media>
      <section class="py-1">
        <b-row>
          <b-col cols="10">
            <h2 class="mb-2">Items Class List</h2>
          </b-col>
          <b-col cols="2">
            <div class="text-center">
              <items-class-create-form :display_order="total_items" @create="createItemClass" />
            </div>
          </b-col>
        </b-row>
      </section>
    </b-media>
    <b-card>
      <items-class-table 
        @getList="getList" 
        :items="items"
      />
    </b-card>
    <b-pagination
      class="mt-auto"
      pills
      size="lg"
      align="center"
      v-model="current_page"
      :total-rows="total_items"
      :per-page="items_perpage"
    />
  </div>
</template>
<script>
import service from '../service'
import ItemsClassTable from './_components/ItemsClassTable.vue'
import ItemsClassCreateForm from './_components/ItemsClassCreateForm.vue'
export default {
  components: {
    ItemsClassTable,
    ItemsClassCreateForm
  },
  data(){
    return {
      current_page: 1,
      total_items: 0,
      items_perpage: 15,
      items: [],
      editLanguage: null,
      filter: {},
    }
  },
  watch:{
    current_page() {
      this.getList();
    },
  },
  created() {
    this.getList();
  },
  methods: {
    async getList(filter) {
      this.filter = filter ? filter : this.filter
      let response = await service.getList({
        page: this.current_page,
        limit: this.items_perpage,
        query: JSON.stringify(this.filter)
      });
      if (response.data.type === "DATA") {
        this.items = response.data.data.list;
        this.total_items = response.data.data.total;
      }
    },
    createItemClass(){
      this.getList();
    }
  },
}
</script>